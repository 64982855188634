@font-face {
  font-family: "cabin-medium";
  src: url("static/fonts/Cabin-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "cabin-bold";
  src: url("static/fonts/Cabin-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-BoldItalic";
  src: url("static/fonts/Cabin-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-Italic";
  src: url("static/fonts/Cabin-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-MediumItalic";
  src: url("static/fonts/Cabin-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-Regular";
  src: url("static/fonts/Cabin-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-SemiBold";
  src: url("static/fonts/Cabin-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "cabin-SemiBoldItalic";
  src: url("static/fonts/Cabin-SemiBoldItalic.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "cabin-Regular";
  overflow: -moz-scrollbars-none;
  min-height: 100vh;
  background-color: #f6f7fb;
  font-size: 16px;
  -ms-overflow-style: none;
  color: #333333;
  font-weight: 400;
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
body::-webkit-scrollbar {
  width: 0;
}

input,
button {
  border: unset;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

ul,
ol {
  padding: 0;
  list-style: none;
}

.wrapper {
  padding: 95px 0 0;
}

.lds-dual-ring {
  position: relative;
}

.lds-dual-ring div {
  position: absolute;
  width: 40px;
  height: 40px;
  align-self: center;
  top: 20px;
  left: 50%;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #6021e8 transparent#6021e8 transparent;
  -webkit-animation: lds-dual-ring 1s linear infinite;
  animation: lds-dual-ring 1s linear infinite;
}
.lds-dual-ring {
  width: 100% !important;
  height: 80px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.error-msg {
  padding: 5px 15px;
  color: red;
  position: absolute;
  bottom: -30px;
  left: 0;
}
